import React from "react";

const Copyright = () => {
  return (
    <p className="text-center mb-0"> 
      غلطة خارج تغطية ضمان الجوع &copy;2024 مارس وشركاؤهم. العلامات التجارية
      للجهات الخارجية تابعة لمالكيها
    </p>
  );
};

export default Copyright;
